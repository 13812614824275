import React from "react"
import { Link } from "gatsby"

import Layout from "../components/bs/layout"
//import Image from "../components/image"
import SEO from "../components/bs/seo"

import MainImg from "../images/main_bg.jpg"

import shape1 from "../images/dec/1.png"
import shape2 from "../images/dec/2.png"
import shape3 from "../images/dec/3.png"
import shape4 from "../images/dec/4.png"
import shape5 from "../images/dec/5.png"
import shape6 from "../images/dec/6.png"
import shape7 from "../images/dec/7.png"
import shape8 from "../images/dec/8.png"

const Onama = () => (
  <Layout>
    <SEO
      title="IMMMUNO NATURA - O NAMA I NAŠIM PROIZVODIMA"
      description="IMMMUNO NATURA preparate biramo sa ljubavlju i predanosti i u želji da blagodati prirode u našim proizvodima budu dostupni svima."
    />

    <section
      id="_heroPage"
      className="hero is-large _o-nama"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">O NAMA</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns">
              <div className="column _headline">
                <div className="content">
                  <h2 className="subtitle is-4">
                    IMMMUNO NATURA preparati se prave sa puno ljubavi, pažnje i
                    predanosti i u želji da priroda postane dostupna svima.
                  </h2>
                  <p>
                    Spol, dob ili dru&scaron;tveni status ne smiju biti prepreka
                    zdravom životu u skladu s prirodom. Kao plod 20 godina rada
                    na poljima naučne i tradicionalne medicine, mikrobiologije,
                    biohemije su nastale mnogobrojne recepture koje Vam u vidu
                    IMMMUNO NATURA preparata stavljamo na raspolaganje.
                  </p>
                  <p>
                    U saradnji sa na&scaron;im mnogobrojnim partnerima radimo na
                    identifikaciji prirodnih proizvoda sa najizraženijim
                    blagotvornim dejstvima, nadograđujemo postojeće recepture
                    sofisticiranim metodama te Vam ih kao ekskluzivni odabir
                    preparata stavljamo na raspolaganje.
                  </p>
                  <p>
                    Sve preparate odabiramo i razvijamo po&scaron;tujući prirodu
                    i ekolo&scaron;ke principe ali ih razvijamo i u skladu sa
                    halal standardima tako da ne sadrže alkohol, a od supstanci
                    životinjskog porijekla sadrže samo ono &scaron;to dolazi od
                    pčela.
                  </p>
                  <p>
                    <strong>
                      Svima koji brinu o svom zdravlju, a nisu u mogućnosti da
                      dođu do na&scaron;ih proizvoda u maloprodajnim objektima,
                      omogućili smo jednostavnu online kupovinu sa besplatnom
                      dostavom u Sarajevu i veoma povoljnim uslovima dostave u
                      ostalim dijelovima BiH preko online trgovine{" "}
                      <a href="http://www.zdravo.ba">www.zdravo.ba</a>.
                    </strong>
                  </p>
                  <p>
                    Uvijek rado pružamo stručne usluge i pomažemo Vam pri
                    odabiru odgovarajućeg proizvoda za Va&scaron;e potrebe.
                  </p>
                  <p>
                    Za sva dodatna pitanja, budite slobodni obratiti nam se
                    putem telefona&nbsp;
                    <a href="tel:+38761787346">061 787 346</a>,{" "}
                    <Link to="/kontakt" title="KONTAKTIRAJTE NAS!">
                      kontaktnog formulara
                    </Link>{" "}
                    ili email-a{" "}
                    <a href="mailto:info@immuno-natura.ba">
                      info@immuno-natura.ba
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default Onama
